import axios from 'axios'
import Qs from 'qs'

const instance = axios.create({
  baseURL: 'https://image.yihisxminiid.com/',
  timeout: 10000
})

export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance.get(url, { params }).then((response) => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}


export const request = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    instance.post(url, Qs.stringify(data), {
      headers: {
         //'Content-Type': 'application/json'
        'Content-Type':'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      resolve(response.data.data)
    }, err => {
      reject(err)
    })
  })
}

export const post = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    instance.post(url, Qs.stringify(data), {
      headers: {
         //'Content-Type': 'application/json'
        'Content-Type':'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      resolve(response.data.data)
    }, err => {
      reject(err)
    })
  })
}

export const patch = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    instance.patch(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
        resolve(response.data)
      }).catch(err => {
      reject(err)
    })
  })
}